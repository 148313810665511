.header {
  margin: 0;
}

.defaultItem {
  border: 1px solid var(--main-border-color);
  border-radius: var(--container-border-radius);
  margin-bottom: var(--gap);
  width: 100%;
}

.defaultTrigger {
  background-color: var(--background-color-primary);
  border: none;
  cursor: pointer;
  padding: var(--gap-small) rem(20px);
  width: 100%;
}

.defaultContent {
  --accordion-content-padding: var(--gap) rem(20px);
}

.item {
  --accordion-border-color: var(--main-border-color);
  --accordion-horizontal-gap: var(--gap);
  --accordion-trigger-vertical-gap: var(--gap-small);
  --accordion-content-vertical-gap: var(--gap-small);
  --accordion-arrow-color: inherit;
  --accordion-arrow-size: rem(15px);
  border: 1px solid var(--accordion-border-color);
  border-radius: var(--container-border-radius);

  width: 100%;

  &[data-open='true'] .arrow {
    transform: rotate(180deg);
  }
}

.arrow {
  color: var(--accordion-arrow-color);
  flex-shrink: 0;
  margin-left: var(--gap);
  transition: var(--transition-duration) ease-in-out;
  width: var(--accordion-arrow-size);
}

.trigger {
  align-items: center;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: var(--accordion-trigger-vertical-gap) var(--accordion-horizontal-gap);
  width: 100%;
}

.content {
  overflow: hidden;
  padding: var(--accordion-content-vertical-gap) var(--accordion-horizontal-gap);
}
