.buttonsWrapper {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.button {
  margin: 0;
  max-width: rem(300px);
  width: 100%;
}

.accent {
  color: var(--color-accent-primary);
}
