.list {
  --list-item-width: rem(300px);
  display: grid;
  gap: var(--gap-large);
  grid-template-columns: repeat(auto-fill, minmax(var(--list-item-width), 1fr));
  justify-content: space-between;
  justify-items: center;

  margin: 0;
  padding: 0;

  &::before {
    clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: 1.5s all cubic-bezier(0.33, 0.42, 0.7, 0.97);
    @media (--tablet-) {
      display: none;
    }
  }

  &[data-is-animation='true']::before {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  @media (--tablet-only) {
    grid-template-columns: 1fr;
    text-align: left;
  }

  @media (--phone) {
    gap: var(--gap-tiny);
  }
}

.container {
  text-align: center;

  @media (--tablet-) {
    overflow: hidden;
  }
}

.subTitle {
  color: var(--color-primary-300);
  margin: 0;
  text-transform: uppercase;
}

.sectionTitle {
  color: var(--color-primary-500);
  margin: var(--gap-tiny);
  margin-bottom: var(--gap-title);
}

.sectionDescription {
  margin: 0 auto;
  margin-bottom: var(--gap);
  max-width: rem(600px);
}
