.loader {
  --loader-color: var(--color-accent-primary);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.playBtn {
  height: 20%;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  will-change: transform;
}

.image {
  object-fit: cover;
}
