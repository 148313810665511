.listContainer {
  margin: 0;
  margin-bottom: var(--gap-large);
  padding-left: calc(var(--gap));
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.listWithoutCategory {
  column-gap: var(--gap);
  columns: 3;

  @media (--tablet-) {
    columns: 2;
  }

  @media (--phone) {
    columns: 1;
  }
}

.title {
  color: var(--color-neutral-900);
  font-size: var(--text-s);
  font-weight: 600;
  margin-bottom: var(--gap-small);

  &:first-letter {
    text-transform: uppercase;
  }
}

.listItem {
  break-inside: avoid;
  margin-bottom: var(--gap-small);
}
