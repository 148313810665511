.title {
  animation: var(--pulse-animation);
  color: var(--color-primary-400);
}

.text {
  color: var(--color-neutral-200);
  font-size: var(--text-m);
  margin-bottom: 70px;

  @media (--phone) {
    margin-bottom: 30px;
  }
}

.container {
  align-items: center;
  display: grid;
  justify-content: center;
  padding: var(--gap) 0;
  text-align: center;
}

.image {
  height: auto;
  max-width: 427px;
  object-fit: contain;
  object-position: center;
  width: 100%;
}
