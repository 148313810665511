.section {
  overflow: initial;
  padding-bottom: 0;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -60px;
  padding: var(--gap-large) var(--gap-huge);
  text-align: left;

  @media (--tablet-) {
    padding: var(--gap);
  }

  @media (--phone) {
    flex-direction: column;
  }
}

.list {
  align-items: stretch;
  display: grid;
  gap: 0 var(--gap-small);
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;

  @media (--tablet-) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }

  @media (--phone) {
    flex-direction: column;
  }
}

.item {
  align-items: flex-start;
  display: flex;
  margin: 0;
  padding: var(--gap-tiny) calc(var(--gap) / 2);

  @media (--desktop) {
    &:not(:last-child) {
      border-right: 1px solid var(--color-neutral-40);
    }
  }

  @media (--phone) {
    padding: var(--gap-tiny) 0;
  }
}

.title {
  color: var(--color-neutral-700);
  margin-right: var(--gap);
  padding-top: var(--gap-small);

  @media (--desktop) {
    flex-shrink: 0;
  }

  @media (--phone) {
    margin-bottom: var(--gap);
    margin-right: 0;
    padding: 0;
  }
}
