@keyframes __zoomIn {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.container {
  aspect-ratio: 16 / 9;
  position: relative;
}

.bg {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video {
  background-color: var(--color-neutral-10);
  border: 1px solid var(--color-neutral-40);
  border-radius: 9px;
  color: var(--color-neutral-0);
  cursor: pointer;
  height: 100%;
  outline: none;
  overflow: hidden;
  padding: var(--gap-large);
  position: absolute;
  transition: var(--transition-duration) all ease;
  width: 100%;

  &:focus-within,
  &:hover {
    outline: 4px solid var(--color-accent-primary);
    outline-offset: 4px;
  }
}

.decor {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;

  &::after {
    background: var(--line-bg);
    bottom: -12%;
    content: '';
    display: block;
    height: 30%;
    position: absolute;
    right: -5%;
    width: 100%;
  }
}

.title {
  font-family: var(--font-family);
  font-size: var(--video-title-size, var(--text-6xl));
  font-weight: var(--bold);
  line-height: 1.1em;
  margin: 0;
  margin-bottom: var(--gap);
  position: relative;
}

.desc {
  font-size: var(--video-desc-size, var(--text-m));
  margin: 0;
  position: relative;
}
