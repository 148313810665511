.details,
.sectionTitle {
  margin-bottom: var(--gap);
}

.title {
  margin-bottom: var(--gap-tiny);
}

.sectionsWrapper {
  display: flex;
  gap: var(--gap-huge);
}

.header {
  border-bottom: 1px solid rgba(1, 1, 1, 0.15);
  margin-bottom: var(--gap);
  padding-bottom: var(--gap);
}

.leftSection,
.rightSection {
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (--tablet-) {
    justify-content: normal;
  }
}

.sectionTitle,
.sectionDetails {
  font-size: var(--text-s);
}

.sectionDetails {
  margin-bottom: var(--gap-small);
}

.sectionTitle {
  color: var(--color-primary-400);
}

.presetsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap-small);
}

.accent {
  color: var(--color-accent-primary);
}

.payoutInput {
  margin-bottom: var(--gap-small);
}
