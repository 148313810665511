.section {
  display: grid;
  gap: var(--gap-title);
  grid-template-areas: 'title all-link' 'list list';
  grid-template-columns: 1.5fr auto;

  @media (--phone) {
    gap: 0;
    grid-template-areas: 'title' 'list' 'all-link';
    grid-template-columns: 1fr;
  }
}

.title {
  color: var(--color-neutral-500);
  grid-area: title;
  margin-bottom: 0;

  @media (--phone) {
    margin-bottom: var(--gap-title);
  }
}

.allLink {
  grid-area: all-link;
  justify-self: end;
  text-transform: initial;
  @media (--phone) {
    margin-top: var(--gap-large);
  }
}

.list {
  grid-area: list;
}
