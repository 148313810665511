.section {
  margin-top: -60px;
  overflow: initial;
  padding-top: 0;
  position: static;
}

.container {
  background-color: var(--background-color-primary);
  border-radius: var(--container-border-radius);
  box-shadow: 0 24px 34px -24px rgba(0, 0, 0, 0.13);
  padding: var(--gap-large);

  @media (--tablet-) {
    padding: var(--gap);
  }
}
