.listItem {
  list-style: none;
  max-width: rem(300px);
  text-align: center;
  width: 100%;

  @media (--tablet-only) {
    align-items: center;
    display: flex;
    max-width: none;
    text-align: left;
  }
}

.iconContainer {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 110px;
  justify-content: center;
  margin: auto;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: 0.8s all cubic-bezier(0.17, 0.67, 0.45, 1.48);
  transition-delay: var(--transition-delay);
  width: 110px;

  @media (--tablet-) {
    transition-delay: 0s;
  }

  @media (--tablet-only) {
    height: 100%;
    justify-content: center;
    margin-left: 0;
    margin-right: var(--gap);
  }

  .animation &,
  [data-is-animation='true'] & {
    opacity: 1;
    transform: scale(1);
  }
}

.primaryIconColor {
  color: var(--color-primary-300);
}

.secondaryIconColor {
  color: #efd14b;
}

.tertiaryIconColor {
  color: var(--color-tertiary-100);
}

.quaternaryIconColor {
  color: var(--color-quaternary-100);
}

.neutralIconColor {
  color: var(--color-neutral-100);
}

.accentIconColor {
  color: var(--color-accent-100);
}

.listItemTitle,
.content {
  opacity: 0;
  transform: translateY(30%);
  transition: 0.8s all ease-in-out;
  transition-delay: var(--transition-delay);
  white-space: pre-wrap;

  .animation &,
  [data-is-animation='true'] & {
    opacity: 1;
    transform: translateY(0);
  }
}

.listItemTitle {
  color: var(--color-primary-400);
  margin: var(--gap) 0;

  @media (--tablet-only) {
    margin-bottom: var(--gap-tiny);
    margin-top: 0;
  }

  @media (--phone) {
    margin: var(--gap-small);
  }
}
