.header {
  margin-bottom: var(--gap-huge);
}

.title {
  color: var(--color-primary-400);
  margin-bottom: var(--gap-huge);
  margin-left: auto;
  margin-right: auto;
  max-width: rem(600px);
  text-align: center;
  white-space: pre-wrap;

  @media (--tablet-) {
    font-size: var(--text-5xl);
  }

  @media (--phone) {
    font-size: var(--text-xl);
  }
}

.titleWithAdditionalInfo {
  margin-bottom: var(--gap);
}

.text {
  color: var(--color-neutral-300);
  margin: 0 auto;
  max-width: rem(580px);
  text-align: center;
  white-space: pre-wrap;

  @media (--tablet) {
    font-size: var(--text-m);
  }
}

.additionalInfo {
  color: var(--color-neutral-400);
  display: block;
  font-size: var(--text-l);
  font-weight: 600;
  margin-bottom: var(--gap-huge);
  text-align: center;

  @media (--phone) {
    margin-bottom: var(--gap);
  }
}
