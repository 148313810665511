.content,
.body {
  padding: 0;
}

.videoContainer {
  aspect-ratio: 16/8.9; /*8.9 for prevent video bottom gap*/
  position: relative;
  z-index: 1;
}

.iframe {
  border: none;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.loader {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.closeBtn {
  position: absolute;
  right: var(--gap-small);
  top: var(--gap-small);
  z-index: 2;
}
