.container {
  text-align: center;
}

.fields {
  display: grid;
  grid-auto-flow: column;
  grid-gap: var(--gap) var(--gap-huge);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  margin-bottom: var(--gap-large);

  @media (--tablet-) {
    grid-gap: var(--gap) var(--gap-large);
  }

  @media (--phone) {
    grid-auto-flow: row;
    grid-gap: var(--gap-small);
    grid-template-columns: 1fr;
    grid-template-rows: initial;
  }
}

.buttons {
  align-items: center;
  display: grid;
  grid-gap: var(--gap);
  grid-template-columns: auto auto;
  justify-content: center;

  @media (--phone) {
    grid-template-columns: 1fr;
    > button:first-child {
      order: 1;
    }
  }
}

.button {
  margin: 0;
  max-width: 100%;
  width: 225px;

  @media (--phone) {
    width: 100%;
  }
}

.formTitle {
  color: var(--color-primary-400);
  font-weight: 600;
  margin-bottom: var(--gap-large);
}
