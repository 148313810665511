.default_header__ji0mM {
  margin: 0;
}

.default_defaultItem__tQvei {
  border: 1px solid #cccbc9;
  border: 1px solid var(--main-border-color);
  border-radius: 4px;
  border-radius: var(--container-border-radius);
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
  width: 100%;
}

.default_defaultTrigger__0ootS {
  background-color: rgba(255,255,255,1);
  background-color: var(--background-color-primary);
  border: none;
  cursor: pointer;
  padding: 0.75rem 1.25rem;
  padding: var(--gap-small) 1.25rem;
  width: 100%;
}

.default_defaultContent__wwDfh {
  --accordion-content-padding: var(--gap) 1.25rem;
}

.default_item__aNQc2 {
  --accordion-border-color: var(--main-border-color);
  --accordion-horizontal-gap: var(--gap);
  --accordion-trigger-vertical-gap: var(--gap-small);
  --accordion-content-vertical-gap: var(--gap-small);
  --accordion-arrow-color: inherit;
  --accordion-arrow-size: 0.9375rem;
  border: 1px solid var(--accordion-border-color);
  border-radius: 4px;
  border-radius: var(--container-border-radius);

  width: 100%;
}

.default_item__aNQc2[data-open='true'] .default_arrow___rzuO {
    transform: rotate(180deg);
  }

.default_arrow___rzuO {
  color: var(--accordion-arrow-color);
  flex-shrink: 0;
  margin-left: 1.5rem;
  margin-left: var(--gap);
  transition: 0.3s ease-in-out;
  transition: var(--transition-duration) ease-in-out;
  width: var(--accordion-arrow-size);
}

.default_trigger__U8ljY {
  align-items: center;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: var(--accordion-trigger-vertical-gap) var(--accordion-horizontal-gap);
  width: 100%;
}

.default_content__GIG5k {
  overflow: hidden;
  padding: var(--accordion-content-vertical-gap) var(--accordion-horizontal-gap);
}

.faq-list_list__9ns8R {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 63rem) {

.faq-list_list__9ns8R {
    position: relative;
}

    .faq-list_list__9ns8R::before {
      background-image: url(/_next/static/media/faq-section-decor.831507a9.svg);
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 259px;
      left: -10%;
      position: absolute;
      top: 1.875rem;
      width: 240px;
      z-index: -1;
    }
  }

.faq-list_item__DXNPx:not(:last-of-type) {
    margin-bottom: 16px;
  }

.faq-list_content__3UKE3 {
  color: #606058;
  color: var(--color-text-primary);
  font-size: rem(14px);
  font-size: var(--text-xs);
  font-weight: 300;
  font-weight: var(--light);
  padding-bottom: 1.5rem;
  padding-bottom: var(--gap);
  position: relative;
  text-align: justify;
}

.faq-list_accordionItem__ddZsO {
  --accordion-content-vertical-gap: 0;
  --accordion-trigger-vertical-gap: var(--gap);
  --accordion-arrow-color: var(--color-accent-primary);
  --accordion-arrow-size: 1.25rem;

  background-color: rgba(255,255,255,1);

  background-color: var(--background-color-primary);
  color: #908f8a;
  color: var(--color-neutral-100);
  text-align: left;
}

.faq-list_accordionItem__ddZsO[focus-within],
  .faq-list_accordionItem__ddZsO.faq-list_focus-visible__zZ5KR,
  .faq-list_accordionItem__ddZsO:hover {
    --accordion-border-color: var(--color-accent-primary);
  }

.faq-list_accordionItem__ddZsO:focus-within,
  .faq-list_accordionItem__ddZsO.faq-list_focus-visible__zZ5KR,
  .faq-list_accordionItem__ddZsO:hover {
    --accordion-border-color: var(--color-accent-primary);
  }

.faq-list_accordionItem__ddZsO[focus-within],
  .faq-list_accordionItem__ddZsO.faq-list_focus-visible__zZ5KR,
  .faq-list_accordionItem__ddZsO:hover {
    --accordion-border-color: var(--color-accent-primary);
  }

.faq-list_accordionItem__ddZsO[focus-within],
  .faq-list_accordionItem__ddZsO:focus-visible,
  .faq-list_accordionItem__ddZsO:hover {
    --accordion-border-color: var(--color-accent-primary);
  }

.faq-list_accordionItem__ddZsO:focus-within,
  .faq-list_accordionItem__ddZsO:focus-visible,
  .faq-list_accordionItem__ddZsO:hover {
    --accordion-border-color: var(--color-accent-primary);
  }

.faq-list_accordionTrigger__wP5oz {
  color: #606058;
  color: var(--color-neutral-500);
  font-size: rem(20px);
  font-size: var(--text-m);
}

@media (max-width: 47.999rem) {

.faq-list_accordionTrigger__wP5oz {
    font-size: rem(16px);
    font-size: var(--text-s);
}
  }

.faq-link_link__4I2XJ {
  color: #5f808b;
  color: var(--color-primary-400);
  text-decoration: none;
}
.faq-link_link__4I2XJ:first-letter {
    text-transform: uppercase;
  }
.faq-link_link__4I2XJ:hover,
  .faq-link_link__4I2XJ:focus {
    text-decoration: underline;
  }

.faq-section_section__s8FqK {
  display: grid;
  grid-gap: 29px;
  grid-gap: var(--gap-title);
  gap: 29px;
  gap: var(--gap-title);
  grid-template-areas: 'title all-link' 'list list';
  grid-template-columns: 1.5fr auto;
}

@media (max-width: 47.999rem) {

.faq-section_section__s8FqK {
    gap: 0;
    grid-template-areas: 'title' 'list' 'all-link';
    grid-template-columns: 1fr;
}
  }

.faq-section_title__b3V5k {
  color: #606058;
  color: var(--color-neutral-500);
  grid-area: title;
  margin-bottom: 0;
}

@media (max-width: 47.999rem) {

.faq-section_title__b3V5k {
    margin-bottom: 29px;
    margin-bottom: var(--gap-title);
}
  }

.faq-section_allLink__tzj2c {
  grid-area: all-link;
  justify-self: end;
  text-transform: initial;
}

@media (max-width: 47.999rem) {

.faq-section_allLink__tzj2c {
    margin-top: calc(1.56 * 1.5rem);
    margin-top: var(--gap-large);
}
  }

.faq-section_list__B_5T8 {
  grid-area: list;
}

.offset-section_section__2bsW8 {
  margin-top: -60px;
  overflow: initial;
  padding-top: 0;
  position: static;
}

.offset-section_container__Lm3yB {
  background-color: rgba(255,255,255,1);
  background-color: var(--background-color-primary);
  border-radius: 4px;
  border-radius: var(--container-border-radius);
  box-shadow: 0 24px 34px -24px rgba(0, 0, 0, 0.13);
  padding: calc(1.56 * 1.5rem);
  padding: var(--gap-large);
}

@media (max-width: 62.999rem) {

.offset-section_container__Lm3yB {
    padding: 1.5rem;
    padding: var(--gap);
}
  }

.faq-trending-section_section__KZuVd {
  overflow: initial;
  padding-bottom: 0;
}

.faq-trending-section_container__zf2Th {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -60px;
  padding: calc(1.56 * 1.5rem) calc(1.56 * calc(1.56 * 1.5rem));
  padding: var(--gap-large) var(--gap-huge);
  text-align: left;
}

@media (max-width: 62.999rem) {

.faq-trending-section_container__zf2Th {
    padding: 1.5rem;
    padding: var(--gap);
}
  }

@media (max-width: 47.999rem) {

.faq-trending-section_container__zf2Th {
    flex-direction: column;
}
  }

.faq-trending-section_list__uf3M7 {
  align-items: stretch;
  display: grid;
  grid-gap: 0 0.75rem;
  grid-gap: 0 var(--gap-small);
  gap: 0 0.75rem;
  gap: 0 var(--gap-small);
  grid-template-columns: repeat(4, 1fr);
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

@media (max-width: 62.999rem) {

.faq-trending-section_list__uf3M7 {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
}
  }

@media (max-width: 47.999rem) {

.faq-trending-section_list__uf3M7 {
    flex-direction: column;
}
  }

.faq-trending-section_item__rirAQ {
  align-items: flex-start;
  display: flex;
  margin: 0;
  padding: 0.375rem calc(1.5rem / 2);
  padding: var(--gap-tiny) calc(var(--gap) / 2);
}

@media (min-width: 63rem) {
    .faq-trending-section_item__rirAQ:not(:last-child) {
      border-right: 1px solid #e4e4e3;
      border-right: 1px solid var(--color-neutral-40);
    }
  }

@media (max-width: 47.999rem) {

.faq-trending-section_item__rirAQ {
    padding: 0.375rem 0;
    padding: var(--gap-tiny) 0;
}
  }

.faq-trending-section_title__GSa19 {
  color: #48473e;
  color: var(--color-neutral-700);
  margin-right: 1.5rem;
  margin-right: var(--gap);
  padding-top: 0.75rem;
  padding-top: var(--gap-small);
}

@media (min-width: 63rem) {

.faq-trending-section_title__GSa19 {
    flex-shrink: 0;
}
  }

@media (max-width: 47.999rem) {

.faq-trending-section_title__GSa19 {
    margin-bottom: 1.5rem;
    margin-bottom: var(--gap);
    margin-right: 0;
    padding: 0;
}
  }

.faq-sub-category_listContainer__2sHSV {
  margin: 0;
  margin-bottom: calc(1.56 * 1.5rem);
  margin-bottom: var(--gap-large);
  padding-left: calc(1.5rem);
  padding-left: calc(var(--gap));
}

.faq-sub-category_list__BO1tj {
  list-style: none;
  margin: 0;
  padding: 0;
}

.faq-sub-category_listWithoutCategory__u_Fmt {
  column-gap: 1.5rem;
  column-gap: var(--gap);
  columns: 3;
}

@media (max-width: 62.999rem) {

.faq-sub-category_listWithoutCategory__u_Fmt {
    columns: 2;
}
  }

@media (max-width: 47.999rem) {

.faq-sub-category_listWithoutCategory__u_Fmt {
    columns: 1;
}
  }

.faq-sub-category_title__bSnJE {
  color: #313026;
  color: var(--color-neutral-900);
  font-size: rem(16px);
  font-size: var(--text-s);
  font-weight: 600;
  margin-bottom: 0.75rem;
  margin-bottom: var(--gap-small);
}

.faq-sub-category_title__bSnJE:first-letter {
    text-transform: uppercase;
  }

.faq-sub-category_listItem__qnIcI {
  page-break-inside: avoid;
  break-inside: avoid;
  margin-bottom: 0.75rem;
  margin-bottom: var(--gap-small);
}

@keyframes react-loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}

.react-loading-skeleton {
    --base-color: #ebebeb;
    --highlight-color: #f5f5f5;
    --animation-duration: 1.5s;
    --animation-direction: normal;
    --pseudo-element-display: block; /* Enable animation */

    background-color: var(--base-color);

    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    line-height: 1;

    position: relative;
    overflow: hidden;
    z-index: 1; /* Necessary for overflow: hidden to work correctly in Safari */
}

.react-loading-skeleton::after {
    content: ' ';
    display: var(--pseudo-element-display);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-repeat: no-repeat;
    background-image: linear-gradient(
        90deg,
        var(--base-color),
        var(--highlight-color),
        var(--base-color)
    );
    transform: translateX(-100%);

    animation-name: react-loading-skeleton;
    animation-direction: var(--animation-direction);
    animation-duration: var(--animation-duration);
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.AdvantagesItem_listItem__fOpAp {
  list-style: none;
  max-width: 18.75rem;
  text-align: center;
  width: 100%;
}

@media (min-width: 48rem) and (max-width: 62.999rem) {

.AdvantagesItem_listItem__fOpAp {
    align-items: center;
    display: flex;
    max-width: none;
    text-align: left;
}
  }

.AdvantagesItem_iconContainer__RvFEE {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 110px;
  justify-content: center;
  margin: auto;
  opacity: 0;
  transform: scale(0);
  transform-origin: center;
  transition: 0.8s all cubic-bezier(0.17, 0.67, 0.45, 1.48);
  transition-delay: var(--transition-delay);
  width: 110px;
}

@media (max-width: 62.999rem) {

.AdvantagesItem_iconContainer__RvFEE {
    transition-delay: 0s;
}
  }

@media (min-width: 48rem) and (max-width: 62.999rem) {

.AdvantagesItem_iconContainer__RvFEE {
    height: 100%;
    justify-content: center;
    margin-left: 0;
    margin-right: 1.5rem;
    margin-right: var(--gap);
}
  }

.AdvantagesItem_animation__OzXZt .AdvantagesItem_iconContainer__RvFEE,
  [data-is-animation='true'] .AdvantagesItem_iconContainer__RvFEE {
    opacity: 1;
    transform: scale(1);
  }

.AdvantagesItem_primaryIconColor__N_j_C {
  color: #87b7c6;
  color: var(--color-primary-300);
}

.AdvantagesItem_secondaryIconColor__CMmvH {
  color: #efd14b;
}

.AdvantagesItem_tertiaryIconColor__N5v1c {
  color: #dabfab;
  color: var(--color-tertiary-100);
}

.AdvantagesItem_quaternaryIconColor__u552F {
  color: #c9cda7;
  color: var(--color-quaternary-100);
}

.AdvantagesItem_neutralIconColor__EQHGo {
  color: #908f8a;
  color: var(--color-neutral-100);
}

.AdvantagesItem_accentIconColor__5j5zT {
  color: #ff3368;
  color: var(--color-accent-100);
}

.AdvantagesItem_listItemTitle__cmhOe,
.AdvantagesItem_content__lmD4f {
  opacity: 0;
  transform: translateY(30%);
  transition: 0.8s all ease-in-out;
  transition-delay: var(--transition-delay);
  white-space: pre-wrap;
}

.AdvantagesItem_animation__OzXZt .AdvantagesItem_listItemTitle__cmhOe,
  [data-is-animation='true'] .AdvantagesItem_listItemTitle__cmhOe,
  .AdvantagesItem_animation__OzXZt .AdvantagesItem_content__lmD4f,
  [data-is-animation='true'] .AdvantagesItem_content__lmD4f {
    opacity: 1;
    transform: translateY(0);
  }

.AdvantagesItem_listItemTitle__cmhOe {
  color: #5f808b;
  color: var(--color-primary-400);
  margin: 1.5rem 0;
  margin: var(--gap) 0;
}

@media (min-width: 48rem) and (max-width: 62.999rem) {

.AdvantagesItem_listItemTitle__cmhOe {
    margin-bottom: 0.375rem;
    margin-bottom: var(--gap-tiny);
    margin-top: 0;
}
  }

@media (max-width: 47.999rem) {

.AdvantagesItem_listItemTitle__cmhOe {
    margin: 0.75rem;
    margin: var(--gap-small);
}
  }

.waiting-content_title__l4u9Z {
  animation: waiting-content__pulse__ux_Gu 1.2s ease infinite;
  animation: var(--pulse-animation);
  color: #5f808b;
  color: var(--color-primary-400);
}

.waiting-content_text__SSow1 {
  color: #83837d;
  color: var(--color-neutral-200);
  font-size: rem(20px);
  font-size: var(--text-m);
  margin-bottom: 70px;
}

@media (max-width: 47.999rem) {

.waiting-content_text__SSow1 {
    margin-bottom: 30px;
}
  }

.waiting-content_container__cscNR {
  align-items: center;
  display: grid;
  justify-content: center;
  padding: 1.5rem 0;
  padding: var(--gap) 0;
  text-align: center;
}

.waiting-content_image__EVBDz {
  height: auto;
  max-width: 427px;
  object-fit: contain;
  object-position: center;
  width: 100%;
}

.AdvantagesList_list__e9zlu {
  --list-item-width: 18.75rem;
  display: grid;
  grid-gap: calc(1.56 * 1.5rem);
  grid-gap: var(--gap-large);
  gap: calc(1.56 * 1.5rem);
  gap: var(--gap-large);
  grid-template-columns: repeat(auto-fill, minmax(var(--list-item-width), 1fr));
  justify-content: space-between;
  justify-items: center;

  margin: 0;
  padding: 0;
}

.AdvantagesList_list__e9zlu::before {
    -webkit-clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
            clip-path: polygon(0 0, 0 0, 0 100%, 0 100%);
    transition: 1.5s all cubic-bezier(0.33, 0.42, 0.7, 0.97);
  }

@media (max-width: 62.999rem) {

.AdvantagesList_list__e9zlu::before {
      display: none;
  }
    }

.AdvantagesList_list__e9zlu[data-is-animation='true']::before {
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

@media (min-width: 48rem) and (max-width: 62.999rem) {

.AdvantagesList_list__e9zlu {
    grid-template-columns: 1fr;
    text-align: left;
}
  }

@media (max-width: 47.999rem) {

.AdvantagesList_list__e9zlu {
    gap: 0.375rem;
    gap: var(--gap-tiny);
}
  }

.AdvantagesList_container__g6uKS {
  text-align: center;
}

@media (max-width: 62.999rem) {

.AdvantagesList_container__g6uKS {
    overflow: hidden;
}
  }

.AdvantagesList_subTitle__LSTSb {
  color: #87b7c6;
  color: var(--color-primary-300);
  margin: 0;
  text-transform: uppercase;
}

.AdvantagesList_sectionTitle__OGGmT {
  color: #527079;
  color: var(--color-primary-500);
  margin: 0.375rem;
  margin: var(--gap-tiny);
  margin-bottom: 29px;
  margin-bottom: var(--gap-title);
}

.AdvantagesList_sectionDescription__TmB9J {
  margin: 0 auto;
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
  max-width: 37.5rem;
}

.not-available-dialog_container__vYF2S {
  text-align: center;
}

.not-available-dialog_fields__qwSMS {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1.5rem calc(1.56 * calc(1.56 * 1.5rem));
  grid-gap: var(--gap) var(--gap-huge);
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;
  margin-bottom: calc(1.56 * 1.5rem);
  margin-bottom: var(--gap-large);
}

@media (max-width: 62.999rem) {

.not-available-dialog_fields__qwSMS {
    grid-gap: 1.5rem calc(1.56 * 1.5rem);
    grid-gap: var(--gap) var(--gap-large);
}
  }

@media (max-width: 47.999rem) {

.not-available-dialog_fields__qwSMS {
    grid-auto-flow: row;
    grid-gap: 0.75rem;
    grid-gap: var(--gap-small);
    grid-template-columns: 1fr;
    grid-template-rows: initial;
}
  }

.not-available-dialog_buttons__osn8C {
  align-items: center;
  display: grid;
  grid-gap: 1.5rem;
  grid-gap: var(--gap);
  grid-template-columns: auto auto;
  justify-content: center;
}

@media (max-width: 47.999rem) {

.not-available-dialog_buttons__osn8C {
    grid-template-columns: 1fr;
}
    .not-available-dialog_buttons__osn8C > button:first-child {
      order: 1;
    }
  }

.not-available-dialog_button__WY7MB {
  margin: 0;
  max-width: 100%;
  width: 225px;
}

@media (max-width: 47.999rem) {

.not-available-dialog_button__WY7MB {
    width: 100%;
}
  }

.not-available-dialog_formTitle__6LJQt {
  color: #5f808b;
  color: var(--color-primary-400);
  font-weight: 600;
  margin-bottom: calc(1.56 * 1.5rem);
  margin-bottom: var(--gap-large);
}

.video-modal_content__YUcgE,
.video-modal_body__mUjOc {
  padding: 0;
}

.video-modal_videoContainer__cTKUZ { /*8.9 for prevent video bottom gap*/
  position: relative;
  z-index: 1;
}

.video-modal_videoContainer__cTKUZ::before {
  float: left;
  padding-top: 55.625%;
  content: '';
}

.video-modal_videoContainer__cTKUZ::after {
  display: block;
  content: '';
  clear: both;
}

.video-modal_iframe__DTSzS {
  border: none;
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video-modal_loader__Kkh0i {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-modal_closeBtn__5FYPq {
  position: absolute;
  right: 0.75rem;
  right: var(--gap-small);
  top: 0.75rem;
  top: var(--gap-small);
  z-index: 2;
}

.conent-header_header__h2r2L {
  margin-bottom: calc(1.56 * calc(1.56 * 1.5rem));
  margin-bottom: var(--gap-huge);
}

.conent-header_title__G4cbW {
  color: #5f808b;
  color: var(--color-primary-400);
  margin-bottom: calc(1.56 * calc(1.56 * 1.5rem));
  margin-bottom: var(--gap-huge);
  margin-left: auto;
  margin-right: auto;
  max-width: 37.5rem;
  text-align: center;
  white-space: pre-wrap;
}

@media (max-width: 62.999rem) {

.conent-header_title__G4cbW {
    font-size: rem(42px);
    font-size: var(--text-5xl);
}
  }

@media (max-width: 47.999rem) {

.conent-header_title__G4cbW {
    font-size: rem(30px);
    font-size: var(--text-xl);
}
  }

.conent-header_titleWithAdditionalInfo__oW75h {
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
}

.conent-header_text__itJ9c {
  color: #777670;
  color: var(--color-neutral-300);
  margin: 0 auto;
  max-width: 36.25rem;
  text-align: center;
  white-space: pre-wrap;
}

@media (min-width: 48rem) {

.conent-header_text__itJ9c {
    font-size: rem(20px);
    font-size: var(--text-m);
}
  }

.conent-header_additionalInfo__pdW39 {
  color: #6d6c65;
  color: var(--color-neutral-400);
  display: block;
  font-size: rem(24px);
  font-size: var(--text-l);
  font-weight: 600;
  margin-bottom: calc(1.56 * calc(1.56 * 1.5rem));
  margin-bottom: var(--gap-huge);
  text-align: center;
}

@media (max-width: 47.999rem) {

.conent-header_additionalInfo__pdW39 {
    margin-bottom: 1.5rem;
    margin-bottom: var(--gap);
}
  }

.video-placeholder_loader__mvxV7 {
  --loader-color: var(--color-accent-primary);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.video-placeholder_playBtn__EN3mC {
  height: 20%;
  left: 50%;
  object-fit: contain;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 20%;
  will-change: transform;
}

.video-placeholder_image__uPocO {
  object-fit: cover;
}

.price-calculations_details__8nicO,
.price-calculations_sectionTitle__VAm0w {
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
}

.price-calculations_title__qPoor {
  margin-bottom: 0.375rem;
  margin-bottom: var(--gap-tiny);
}

.price-calculations_sectionsWrapper__HWhg1 {
  display: flex;
  gap: calc(1.56 * calc(1.56 * 1.5rem));
  gap: var(--gap-huge);
}

.price-calculations_header__NBbdN {
  border-bottom: 1px solid rgba(1, 1, 1, 0.15);
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
  padding-bottom: 1.5rem;
  padding-bottom: var(--gap);
}

.price-calculations_leftSection__x0sv_,
.price-calculations_rightSection__v3vrG {
  display: flex;
  flex-direction: column;
  width: 50%;
}

@media (max-width: 62.999rem) {

.price-calculations_leftSection__x0sv_,
.price-calculations_rightSection__v3vrG {
    justify-content: normal;
}
  }

.price-calculations_sectionTitle__VAm0w,
.price-calculations_sectionDetails__as6Hn {
  font-size: rem(16px);
  font-size: var(--text-s);
}

.price-calculations_sectionDetails__as6Hn {
  margin-bottom: 0.75rem;
  margin-bottom: var(--gap-small);
}

.price-calculations_sectionTitle__VAm0w {
  color: #5f808b;
  color: var(--color-primary-400);
}

.price-calculations_presetsWrapper__6Zv3Z {
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem;
  gap: var(--gap-small);
}

.price-calculations_accent__D1xcq {
  color: #ff3368;
  color: var(--color-accent-primary);
}

.price-calculations_payoutInput__dllwA {
  margin-bottom: 0.75rem;
  margin-bottom: var(--gap-small);
}

@keyframes video___zoomIn__TfEYf {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.video_container__nG8xh {
  position: relative;
}

.video_container__nG8xh::before {
  float: left;
  padding-top: 56.25%;
  content: '';
}

.video_container__nG8xh::after {
  display: block;
  content: '';
  clear: both;
}

.video_bg__jOE1J {
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.video_video__gPgKe {
  background-color: #fbfbfb;
  background-color: var(--color-neutral-10);
  border: 1px solid #e4e4e3;
  border: 1px solid var(--color-neutral-40);
  border-radius: 9px;
  color: #ffffff;
  color: var(--color-neutral-0);
  cursor: pointer;
  height: 100%;
  outline: none;
  overflow: hidden;
  padding: calc(1.56 * 1.5rem);
  padding: var(--gap-large);
  position: absolute;
  transition: 0.3s all ease;
  transition: var(--transition-duration) all ease;
  width: 100%;
}

.video_video__gPgKe[focus-within],
  .video_video__gPgKe:hover {
    outline: 4px solid #ff3368;
    outline: 4px solid var(--color-accent-primary);
    outline-offset: 4px;
  }

.video_video__gPgKe[focus-within],
  .video_video__gPgKe:hover {
    outline: 4px solid #ff3368;
    outline: 4px solid #ff3368;
    outline: 4px solid var(--color-accent-primary);
    outline-offset: 4px;
  }

.video_video__gPgKe:focus-within,
  .video_video__gPgKe:hover {
    outline: 4px solid #ff3368;
    outline: 4px solid var(--color-accent-primary);
    outline-offset: 4px;
  }

.video_decor__uRkQ1 {
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
}

.video_decor__uRkQ1::after {
    background: repeating-linear-gradient(
    -45deg,
    #cee1e8,
    #cee1e8 2px,
    transparent 2px,
    transparent calc(2px * 3)
  );
    background: var(--line-bg);
    bottom: -12%;
    content: '';
    display: block;
    height: 30%;
    position: absolute;
    right: -5%;
    width: 100%;
  }

.video_title__Qlbr9 {
  font-family: var(--font-family);
  font-size: rem(51px);
  font-size: var(--video-title-size, var(--text-6xl));
  font-weight: 600;
  font-weight: var(--bold);
  line-height: 1.1em;
  margin: 0;
  margin-bottom: 1.5rem;
  margin-bottom: var(--gap);
  position: relative;
}

.video_desc__5QTqm {
  font-size: rem(20px);
  font-size: var(--video-desc-size, var(--text-m));
  margin: 0;
  position: relative;
}

.available-market_buttonsWrapper__oSXqF {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.available-market_button__ItPlG {
  margin: 0;
  max-width: 18.75rem;
  width: 100%;
}

.available-market_accent__3c_3_ {
  color: #ff3368;
  color: var(--color-accent-primary);
}

