.list {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (--desktop) {
    position: relative;

    &::before {
      background-image: url('./faq-section-decor.svg');
      background-repeat: no-repeat;
      background-size: contain;
      content: '';
      height: 259px;
      left: -10%;
      position: absolute;
      top: rem(30px);
      width: 240px;
      z-index: -1;
    }
  }
}

.item {
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

.content {
  color: var(--color-text-primary);
  font-size: var(--text-xs);
  font-weight: var(--light);
  padding-bottom: var(--gap);
  position: relative;
  text-align: justify;
}

.accordionItem {
  --accordion-content-vertical-gap: 0;
  --accordion-trigger-vertical-gap: var(--gap);
  --accordion-arrow-color: var(--color-accent-primary);
  --accordion-arrow-size: rem(20px);

  background-color: var(--background-color-primary);
  color: var(--color-neutral-100);
  text-align: left;

  &:focus-within,
  &:focus-visible,
  &:hover {
    --accordion-border-color: var(--color-accent-primary);
  }
}

.accordionTrigger {
  color: var(--color-neutral-500);
  font-size: var(--text-m);

  @media (--phone) {
    font-size: var(--text-s);
  }
}
