.link {
  color: var(--color-primary-400);
  text-decoration: none;

  &:first-letter {
    text-transform: uppercase;
  }

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}
